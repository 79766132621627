<template>
    <!--begin::Signin-->
    <div class="login-form login-forgot">
      <!--begin::Title-->
      <div class="pt-lg-0 pt-5">
          <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">{{ title }}</h3>
          <p class="text-muted font-weight-bold font-size-h4">{{ this.message }}</p>
          <b-button @click="$router.push({ name: 'auth-login' })" v-if="errors" class="px-7 p-2 font-weight-bold" variant="success">Kembali</b-button>
      </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { CALLBACK } from "@/core/services/store/auth.module";

export default {
  name: "auth-callback",
  mounted() {
    if (! this.$route.query.code) {
        this.$router.push({ 
          name: 'auth-login', 
          query: {
            rget: this.$route.query.rget
          } 
        })
        return
    }
    // Simulate the delay page loading
    // setTimeout(() => {
      // Remove page loader after some time
      this.$store
          .dispatch(CALLBACK, { code: this.$route.query.code })
          // go to which page after successfully login
          .then(() => {
            const getNextPage = window.localStorage.getItem("rget_as_next")
            if (getNextPage) {
              window.localStorage.removeItem("rget_as_next")
              return this.$router.push({ path: getNextPage }).catch(()=>{})
            }
            this.$router.push({name: "auth-select-company"})
          });
    // }, 2000);
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    title () {
      if (this.errors) {
        return 'Terjadi Kesalahan!!'
      }
      return 'Silahkan Tunnggu..'
    },
    message () {
      if (this.errors) {
        return this.errors
      }
      return 'Memproses data silahkan menunggu'
    }
  }
};
</script>
